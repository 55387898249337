@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url(./assets/styles/universal.css);

body {
  font-family: "Manrope", sans-serif;
}

.contact-ribbon {
  position: fixed;
  bottom: 140px;
  right: 0;
  z-index: 1000;
  background: var(--background-color);
  color: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  cursor: pointer;

  font-weight: bold;
  box-shadow: var(--elevate-2);
}

.contact-ribbon:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.contact-ribbon p {
  color: var(--text-color);
  margin: 0px;
}
.spt-ghost-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.how-it-works .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.how-it-works img {
  width: 100%;
  object-fit: contain;
}
.how-it-works-item {
  margin-bottom: 20px;
}

.how-it-works-item h6 {
  color: var(--text-color);
  font-weight: bold;
  text-transform: uppercase;
}
.spt-benefits-part {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.spt-benefits-item {
  border: solid 1px #e9e9e9;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  height:max-content;
  align-items: start;
  margin-right: 2%;
}
.spt-benefits-item img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  object-fit: contain;
}
.spt .spt-t-area p {
  text-align: center;
}
.spt-t-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spt-contact-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--elevate-float);
  position: absolute;
  z-index: 5;
}

.spt-contact-root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-image) no-repeat center center;
  width: 100%;
  min-height: 500px;
}

.spt-v2-nav img {
  object-fit: contain;
}
.spt-v2-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: transparent;
  align-items: center;
  height: auto;
  z-index: 1000;
  padding: 20px;
}
/* -- SPT THEME ---- */

.spt-media-player {
  height: 360px;
  background-color: #383838;
  overflow-y: hidden;
  /* width: 100%; */
}
.spt-about-chip i {
  color: #7eba7e;
}
.spt-about-chip {
  border: solid 1px #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-touchable-opacity {
  cursor: pointer;
}
.s-touchable-opacity:hover {
  opacity: 0.8;
  transition: 0.2s ease-in-out;
}

.spt-overlay-content .bottom-content {
}
.p-button {
  border: solid 1px grey;
  /* padding: 10px; */
  border-radius: 5px;
  background-color: #ffffff1c;
}
.play-btn {
  color: #ffffffcf;
  /* margin-bottom: 20px; */
}

.spt-overlay-content {
  color: white;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spt-hero-overlay {
  background: rgb(0 58 68 / 53%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.spt-section-title {
  margin: 0px;
  font-weight: normal;
}
.spt-acc-t-opened {
  background-color: var(--spt-main-color);
  color: white;
}
.spt-acc-title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.spt-acc-title h4 {
  /* font-weight: normal; */
  margin: 0;
}
.spt-get-help {
  display: flex;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 10px;
  align-items: center;
}
.spt-goal-section {
  background: var(--spt-accent-3);
}
.spt-section-style {
  border: 1px solid rgb(243, 243, 243);
}

.spt-section-margin {
}
.spt-section .img {
  width: 90%;
  object-fit: cover;
  border-radius: 20px;
}
.hero-container {
  color: white;
  background-color: rgba(0, 58, 68, 1);
}
.spt-hero .intro h6 {
  color: var(--spt-accent-2);
}

.spt-hero .intro h1 {
  color: white;
  font-weight: normal;
  text-transform: capitalize;
}

.spt-btn {
  background-color: var(--background);
  color: white;
  border: 0px;
  border-radius: 5px;
  /* font-weight: bold; */
  cursor: pointer;
  /* width: fit-content; */
  text-align: center;
}

.spt-btn-outline {
  margin-bottom: 5px;
  background: white;
  border: 1px var(--border-color) solid;
  color: var(--text-color);
  border-radius: 4px;
  width: auto;
  margin-top: 10px;
  text-align: center;
}

/* ------------------ */

.floating-l-drop {
  border-radius: 5px;
  /* position: fixed;
  bottom: 60px;
  right: 0px; */
  padding: 5px;
  /* border-top-left-radius: 55px;
  border-bottom-left-radius: 55px; */
  box-shadow: var(--elevate-5);
  background-color: white;
  color: var(--app-accent-3);
  /* opacity: 0.7; */
  cursor: pointer;
}

.floating-l-drop > select {
  cursor: pointer;
  color: var(--app-main-color);
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
  width: auto;
}

.floating-l-drop:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.undefault {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.undefault:active,
.undefault:focus {
  outline: 0;
  border: 0;
}
.l-drop {
  padding: 5px;
  cursor: pointer;
  /* border: solid 2px var(--app-main-color); */
  border: solid 2px #e4e4ff;
  margin: 0px 20px;
  border-radius: 3px;
  display: inline-block;
}

.l-drop > select {
  color: var(--app-main-color);
}

.table-trashcan {
  padding: 0.5rem;
  /* width: 2rem;
	height: 2rem; */
  font-size: 0.875rem;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-trashcan:hover {
  background-color: red;
  color: white;
}
.tech-btn {
  border-radius: 500px !important;
  padding: 8px 23px !important;
  margin: 6px 0;
  font-size: var(--small-font) !important;
  border-width: 0px !important;
  font-weight: bold !important;
}

::-webkit-scrollbar {
  display: none !important;
}
