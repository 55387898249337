$admin-accent-color: #6e207c;
$input-text-color: #000;
$input-focus-outline: $admin-accent-color;

.theme-color {
  color: #6e207c;
}

.custom-letter-spacing-p {
  letter-spacing: 1px;
}

.light-red-background {
  background-color: rgb(255, 157, 157);
}

/* .......................................  input styles  ................................... */

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: static;
}

.input-container label.text {
  font-size: 0.9rem;
  color: rgb(60, 60, 60);
  font-weight: 450;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  left: 12px;
  /* width: 170px !important; */
  padding: 0 20px 0 13px;
  background: #ffffff;
  width: fit-content;
  letter-spacing: 0.05em;
}

.input-container input[type="text"].input,
.input-container input[type="number"].input,
.input-container input[type="email"].input {
  padding: 1rem 1.4rem;
  font-size: 0.9rem;
  border: 1px #202020 solid;
  border-radius: 3px;
  background: #ffffff;
  letter-spacing: 0.05em;
  font-weight: 300;
  color: #000;
}

.input-container input[type="text"].input:focus,
.input-container input[type="number"].input:focus,
.input-container input[type="email"].input:focus {
  outline: none;
}

/* .......................................  input  ----  textarea  ................................... */

.input-textarea {
  width: 100%;
  height: 100%;
  letter-spacing: 0.05em;
  resize: none;
  padding: 15px;
  //min-height: 15rem;
  outline: none;
  font-size: 0.9rem;
  border: 1px #202020 solid;
  border-radius: 3px;
  background: #ffffff;
  font-weight: 200;
  color: #4b5563;
}
/* .......................................  input  ----  date  ................................... */

.date-input {
  width: 100%;
  height: 100%;
  letter-spacing: 0.05em;
  resize: none;
  padding: 1rem 1.4rem;
  outline: none;
  font-size: 0.9rem;
  border: 1px #202020 solid;
  border-radius: 3px;
  background: #ffffff;
  font-weight: 200;
  color: #4b5563;
}

/* .......................................  button no rounded edges styles - btn-fixed   ................................... */

.btn-fixed {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #6e207c;
  color: white;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.05em;
  border: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* .......................................  button rounded edges styles - btn-fixed   ................................... */

.btn-rounded {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #6e207c;
  color: white;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.05em;
  border: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btn-n:hover {
  background-color: #9c4bab;
}

/*  ............................... Button Loader ...................... */

.loader {
  display: inline-grid;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.loader:before,
.loader:after {
  content: "Loading...";
  grid-area: 1/1;
  -webkit-mask: linear-gradient(90deg, #fff 50%, #0000 0) 0 50%/2ch 100%;
  color: #0000;
  text-shadow:
    0 0 0 #fff,
    0 calc(var(--s, 1) * 1.2em) 0 #0000;
  animation: l15 1s infinite;
}
.loader:after {
  -webkit-mask-position: 1ch 50%;
  --s: -1;
}
@keyframes l15 {
  80%,
  100% {
    text-shadow:
      0 calc(var(--s, 1) * -1.2em) 0 #fff,
      0 0 0 #fff;
  }
}

/* .......................................  Chip styles   ................................... */

.chip {
  padding-inline: 10px;
  background-color: #f3f3f3;
  color: black;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 9999px;
  font-size: 0.975rem;
  line-height: 1.25rem;
  letter-spacing: 0.05em;
  font-weight: 500;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 2px solid #f3f3f3;

  button {
    background-color: transparent;
  }
}

.chip:hover {
  border-color: #f87171;

  button {
    background-color: transparent;

    svg {
      fill: #1f2937;
    }
  }
}

/* .......................................  checkbox styles   ................................... */

.custom-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  display: none;
}

.checkbox-icon {
  position: absolute;
  top: 0;
  left: -2px;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 2px;
}

.custom-checkbox input:checked ~ .checkbox-icon {
  background-color: #6e207c;
}

.custom-checkbox .checkbox-icon {
  width: 29.77px;
  height: 29.47px;
}

.custom-checkbox .checkbox-icon {
  border-color: #6e207c;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.checkbox-big .checkbox-icon::after {
  content: "\2713";
  display: block;
  color: #fff;
  font-size: 32px;
  text-align: center;
  line-height: 24px;
}

.checkbox-big .text {
  margin-left: 42px;
  letter-spacing: 1px;
}

.checkbox-small .checkbox-icon {
  width: 23.77px;
  height: 23.47px;
}

.checkbox-small .text {
  margin-left: 35px;
}

.checkbox-small .checkbox-icon::after {
  content: "\2713";
  display: block;
  color: #fff;
  font-size: 25px;
  text-align: center;
  line-height: 19px;
}

/* .......................................  Dropdown styles   ................................... */

.cusdropdown-container {
  position: relative;
  user-select: none;
}

.cusdropdown {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-width: 1px;
  border-color: #6e207c;
  border-style: solid;
  width: 100%;
  letter-spacing: 0.025em;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  z-index: 2;
  background-color: white;
}

.cusdropdown.open {
  border-width: 2px;
}

.cusdropdown-menu {
  position: absolute;
  top: 100%;
  width: 100%;
  display: block;
  overflow-y: scroll;
  background-color: white;
  /* position: absolute; */
  //top: 2rem;
  height: 0;
  left: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  z-index: 1000;
}

.cusdropdown-menu-close {
  height: 0;
}

.cusdropdown-menu-open {
  height: auto;
  padding-top: 10px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cusdropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.cusdropdown-menu li:hover {
  background-color: #f9f9f9;
}

.cusdropdown-toggle .arrow {
  position: absolute;
  right: 1.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.arrow-rotate {
  rotate: 180deg;
}

.cusdropdown-menu ul {
  list-style: none;
}

.cusdropdown-item {
  padding: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
}

.cusdropdown-item:hover {
  background-color: #ebebeb;
}

/* ...................................................   File Upload   ................................... */

.file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  letter-spacing: 0.025em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 290px;
  border-width: 1px;
  border-color: rgb(148, 148, 148);
  border-style: solid;
  border-radius: 3px;
}

.file-input-label {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  //border-radius: 50%;
  border: 2px dashed #ccc;
  //overflow: hidden;
  cursor: pointer;
  background-color: #f2f2f2;
}

.preview-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.file-input {
  opacity: 0;
}

.choose-file-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.choose-file-button span {
  color: #555;
  font-weight: bold;
  background-color: #f2f2f2;
}

/* ...................................... incentives Bar ...................................... */

.incentivesBar-menu-open {
  //height: 45rem;
  padding-top: 2rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cusdropdown-toggle .arrowincentivesBar {
  position: absolute;
  right: 1.25rem;
  top: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.arrowincentivesBar-rotate {
  rotate: 180deg;
}

.incentivesBardropdown {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-width: 1px;
  border-color: #f4f4f4;
  border-style: solid;
  width: 100%;
  letter-spacing: 0.025em;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  z-index: 2;
  background-color: white;
}

.icentiveBarIcon {
  padding-right: 15px;
}

/* ......................... CAMPAIGN STATISTICS POPUP ......................... */

.pop-main-container {
  position: absolute;
  width: 100%;
  max-width: 42rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  overflow: clip;
}
.pop-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.pop-img-container {
  width: 100%;
  height: 100px;
  position: relative;
}

.pop-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.pop-title-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 300px;
  width: 100%;
}

.pop-title-container img {
  border-radius: 500px;
  width: 50px;
  height: 50px;
}

.pop-title-container h3 {
  color: white;
  margin-top: 2rem;
  font-weight: bold;
}

.stats-container {
  display: flex;
  background-color: white;
  padding: 1rem;
  z-index: 3;
  margin-top: 200px;
  width: 100%;
}

.stats-container-on {
  display: flex;
  background-color: white;
  padding: 1rem;
  z-index: 3;
  width: 100%;
  max-width: 42rem;
  margin-top: -30px;
  background-color: transparent;
  /* margin-bottom: 7px; */
}

.edit-btn {
  background-color: white;
  color: #6e207c;
  padding: 0.5rem 1rem;
  border: solid 0.8px black;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.edit-btn:hover {
  background-color: #6e207c;
  color: white;
}

.btn-cont-borders {
  /* margin-bottom: 30px; */
  padding-top: 30px;
  /* padding: 0.5rem 1rem; */
  border-top: 1px solid gray;
}

/* ............................ Statistics Card .......................... */

.stat-card {
  //   width: 220px;

  height: 150px;
  background-color: rgb(88, 222, 255);
  border-radius: 10px;
  padding: 1rem;
  color: white;
}

.stat-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stat-icon {
  width: 40px;
  height: 40px;
  border-radius: 500px;
  /* background-color: rgb(106, 255, 225); */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.bg-one {
  background-color: rgb(88, 222, 255);
}

.bg-two {
  background-color: rgb(88, 222, 255);
}

.bg-three {
  background-color: rgb(88, 222, 255);
}

.bg-four {
  background-color: rgb(88, 222, 255);
}

.bg-five {
  background-color: rgb(88, 222, 255);
}

.bg-six {
  background-color: rgb(88, 222, 255);
}

.bg-cyan-400 {
  background-color: #22d3ee;
}

.bg-lime-400 {
  background-color: #84cc16;
}

.bg-pink-400 {
  background-color: #f43f5e;
}

.bg-indigo-400 {
  background-color: #6366f1;
}
.bg-violet-400 {
  background-color: #7c3aed;
}

.bg-green-400 {
  background-color: #22c55e;
}

.bg-teal-400 {
  background-color: #38b2ac;
}

.bg-rose-400 {
  background-color: #f43f5e;
}

/* bg-cyan-400, bg-lime-400, bg-pink-400, bg-indigo-400 */

.stat-type h5 {
  text-transform: capitalize;
}
// .......................................... comment card .....................................

.comment-card-con {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 50px;
}

.per-tech-comment {
  margin-bottom: 40px;
  // display: flex;
  // flex-direction: column;
  // gap: 50px;
  // flex-wrap: wrap;
  // width: 100%;

  .h4 {
    margin-bottom: 20px;
  }
}

.comm-tabs {
  display: flex;
  margin-top: 50px;

  .p {
    padding: 1rem 2rem;
  }
}

.comments-con {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.comment-card {
  width: 350px;
  height: auto;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  border: 0.5px solid rgb(221, 221, 221);
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  // 	0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* background-color: rgb(255, 244, 246); */
  // background-color: #f7f0ff;
}

.comment-card-expand {
  width: 350px;
  height: fit-content;
  border-radius: 10px;
  padding: 1rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  // background-color: #f7f0ff;
  /* position: absolute;
	--transform-translate-x: -50%;
	--transform-translate-y: -50%;
	left: 50%;
	top: 50%; */
}

// .......................................... comment card .....................................

.black-bg {
  width: 100vh !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.comment-text {
  color: rgb(43, 43, 43);
  //margin: 1rem 0;
  font-size: 14px;
  cursor: pointer;
}
.testimonial-read-more-toggle {
  cursor: pointer;
}
.comment-delete-btn {
  color: rgb(252, 119, 95);
  font-size: 14px;
  cursor: pointer;
}
.comment-delete-btn:hover {
  color: red;
  font-weight: bold;
}

.comment-date {
  display: flex;
  justify-content: end;
  align-content: center;
  color: rgb(186, 186, 186);
}

.sidebar-root-container {
  //max-width: 270px;
  //width: 250px !important;
  //position: absolute !important;
  height: 100vh !important;
  top: 0;
  //width: 100% !important;

  &.enlarge {
    //width: 250px !important;
  }
}

.side-bar-container {
  width: 270px;
  //overflow-x: hidden;

  &.shrink {
    width: 90px;
  }
}

.vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded-left {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-right {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.text-{
  &accent-1 {
    color: var(--app-accent-1);
  }
  &accent-2 {
    color: var(--app-accent-2);
  }
  &accent-3 {
    color: var(--app-accent-3);
  }
}

a.dropdown-toggle.nav-link {
  display: flex !important;

  &::after {
    //display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  svg.down-chevron {
    transition: transform 0.05s ease-in-out;
  }

  &.show svg.down-chevron {
    transform: rotate(180deg);
  }
}

@media (min-width: 176px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .w-sm-100vw {
    width: 100vw !important;
  }
  .h-sm-100vh {
    height: 100vh !important;
  }
}
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-100vw {
    width: 100vw !important;
  }

  .h-md-100 {
    height: 100% !important;
  }
  .h-md-100vh {
    height: 100vh !important;
  }
}
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-100vw {
    width: 100vw !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-100vh {
    height: 100vh !important;
  }
}
