#tick {
  stroke-width: 6;
  transition: all 1s;
}

#circle {
  stroke-width: 6;
  transform-origin: 50px 50px 0;
  transition: all 1s;
}

.progress #tick {
  opacity: 0;
}

.ready #tick {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 8s ease-out forwards;
}

.progress #circle {
  stroke: #ffffff;
  stroke-dasharray: 214;
  stroke-dashoffset: 1000;
  animation: spin 1.22s linear infinite;
}

.ready #circle {
  stroke-dashoffset: 66;
  //stroke: #63bc01;
}

#circle {
  stroke-dasharray: 500;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 66;
  }
  50% {
    transform: rotate(540deg);
    stroke-dashoffset: 314;
  }
  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 66;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

#check {
  width: 300px;
  height: 300px;
}
