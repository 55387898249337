
@media only screen and (min-width: 768px) and (max-width: 1460px) {
  .lang-s-p-override {
    right: 0px !important;
  }
  .lang-s-ribbon {
    background: white;
    /* right:0px; */
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 10px;
    box-shadow: var(--elevate-3);
    padding-left: 20px;
  }
}
