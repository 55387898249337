.flex {
	display: flex;
}

.py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.px-5 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.delay-500 {
	animation-delay: 500ms;
}

.justify-end {
	justify-content: flex-end;
}

.text-gray-100 {
	color: #f7fafc;
}

.duration-700 {
	transition-duration: 700ms;
}

.transition-all {
	transition-property: all;
}

.mt-4 {
	margin-top: 1rem;
}

.group {
	position: relative;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.gap-4 {
	gap: 1rem;
}

.relative {
	position: relative;
}

.text-gray-100 {
	color: #f7fafc;
}

.cursor-pointer {
	cursor: pointer;
}

.hover\:bg-gray-800:hover {
	background-color: #1f2937;
}

.rounded-md {
	border-radius: 0.375rem;
}

.items-center {
	align-items: center;
}

.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.whitespace-pre {
	white-space: pre;
}

.duration-300 {
	transition-duration: 0.3s;
}

.opacity-0 {
	opacity: 0;
}

.translate-x-28 {
	transform: translateX(7rem);
}

.overflow-hidden {
	overflow: hidden;
}

.absolute {
	position: absolute;
}

.left-48 {
	left: 12rem;
}

.w-0 {
	width: 0;
}

.text-sm {
	font-size: 0.875rem;
}

.bg-white {
	background-color: #ffffff;
}

.py-1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.left-4-4 {
	left: 1.1rem;
}

.w-fit {
	width: fit-content;
}

.font-medium {
	font-weight: 500;
}

.drop-shadow-lg {
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.text-gray-900 {
	color: #4b5563;
}

.z-30 {
	z-index: 30;
}

.min-h-screen {
	height: 100vh;
}

/* ---- Accordion---- */
.accordion {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 4px;
  }
  
  .accordion-header {
	padding: 10px;
	background-color: #f8f9fa;
	cursor: pointer;
  }
  
  .accordion-body {
	padding: 10px;
	background-color: #fff;
  }