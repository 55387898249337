$check-box-size : 18px;

.data-table {
  input[type=checkbox] {
    width: $check-box-size;
    height: $check-box-size
  }

  td {
    vertical-align: middle;
    animation: none !important;
    transform: none !important;
    opacity: 1 !important;
  }
}
