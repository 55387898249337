.toggle-switch {
  /* width: 60px; */
  width: 30px;
  height: 15px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  /* background-color: var(--admin-theme-color); */
  background-color: #4caf50;
}

.switch-handle {
  /* width: 26px;
    height: 26px; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: -3px;
  /* top: 2px; */
  /* left: 2px; */
  left: -6px;
  transition: left 0.3s ease;
}

.toggle-switch.on .switch-handle {
  left: 16px;
  /* left: 32px; */
}
