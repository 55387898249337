.hero {
  position: relative;
  height: 60vh;
  overflow: hidden;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}

.hero-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 5; /* Below the content but above the background images */
  pointer-events: none;
}

.hero-slide {
  height: 60vh;
  background-size: cover;
  background-position: center;
}

.hero-content {
  position: relative;
  z-index: 10;
  padding: 50px;
  color: white;
  width: 70%;
}

.hero-title {
  font-size: 6.5vw; /* Gigantic title */
  margin: 0;
  line-height: 1.1;
}

.hero-subtext {
  font-size: 2vw;
  /* margin-top: 10px; */
  max-width: 500px;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-list,
.slick-track {
  height: 100%;
}

.slick-slide {
  height: 100%;
}

/* Style for dots in Slick */
.slick-dots {
  position: absolute;
  bottom: 20px; /* Move dots slightly upward */
  width: 100%;
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white; /* Change the color of the dots */
  opacity: 0.75;
}

.slick-dots li.slick-active button:before {
  color: white; /* Active dot color */
  opacity: 1;
}

/* ---------------------------------------- */

@media only screen and (max-width: 767px) {
  .hero-content {
    width: 100%;
    padding: 30px;
  }
  .hero-title {
    font-size: 11vw; /* Gigantic title */
  }
  .hero-subtext {
    font-size: 5vw;
    margin-top: 10px;
  }
}
