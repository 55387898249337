@media only screen and (max-width: 767px) {
  /* -- SPT THEME ---- */

  .spt-img-for-contain { 
    object-fit: contain !important;
    border-radius: 0px !important;
  }
  .mob-width-auto { 
    width: auto !important;
  }
  .contact-ribbon {
    opacity: 0.5;
    padding: 8px 12px;
    font-size: 13px;
  }
  .spt-contact-form {
    margin: 10px;
  }
  .spt-contact-root {
    /* min-height: 500px; */
  }

  .partner-img {
    height: 60px;
  }
  .how-it-works-item {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  .how-it-works img {
    height: 100px;
    margin: 30px 0px;
  }

  .spt-benefits-item img {
    margin-bottom: 10px;
  }
  .spt-benefits-item {
    flex-basis: 100%;
    flex-direction: column;
  }
  .spt-contact-root {
    padding: 20px 10px;
  }
  .spt-contact-form {
    padding: 16px;
  }
  .spt-v2-nav img {
    margin: 0px;
    height: 75px;
  }
  .spt-section-component {
    padding: 4vh 1vw;
  }
  .spt-section-img-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .row {
    margin: 0px !important;
  }
  .spt-s-img {
    border-radius: 15px;
    width: 95%;
  }
  .spt-hero-media-area {
    height: 250px;
    position: relative;
    background-color: var(--spt-main-color);
  }
  .spt-about-chip {
    padding: 5px 10px;
    margin: 4px;
    font-size: 13px;
  }
  .spt-about-chip i {
    margin-right: 4px;
    font-size: 14px;
  }
  .play-btn {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .p-button {
    padding: 7px 20px;
    margin: 5px 0px;
  }
  .col-on-pc-row-on-mobile {
    display: flex;
    flex-direction: row;
  }
  .row-on-pc-col-on-mobile {
    display: flex;
    flex-direction: column;
  }
  .spt-hero .intro h1 {
    font-size: 2.5rem;
  }
  .spt-get-help div {
    margin-bottom: 5px;
  }

  .spt-get-help h5,
  p,
  img {
    margin-top: 10px;
  }
  .spt-get-help .btn-area {
    width: 100%;
  }
  .spt-footer h1 {
    color: white;
    font-size: 25px;
    font-weight: normal;
    width: 87%;
  }
  .spt-footer {
    background-color: var(--background);
    color: white;
    width: 100%;
    padding: 3% 3%;
    height: 200px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }
  .spt-btn {
    padding: 10px 25px;
    font-size: 15px;
    font-weight: bold;
  }

  .spt-btn-outline {
    padding: 7px 25px;
    font-size: 15px;
    font-weight: bold;
  }
  .mobile-padding {
    padding: var(--my-custom-padding) !important;
  }

  .spt-mobile-section-t {
    font-weight: normal;
    font-size: 1.65rem;
    text-align: center;
    width: 80%;
  }
  .mobile-width {
    width: var(--my-custom-width) !important;
  }
  .mobile-margin {
    margin: var(--my-custom-margin) !important;
  }
  .spt-flex-column-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .spt-section .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .spt-section .img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 150px;
  }
  .spt-section-padding {
    padding: 0% 3%;
  }
  .spt-hero .intro {
    padding: 10% 7%;
  }
  .spt-acc-title {
    padding: 10px 20px;
  }

  .spt-acc-title h4 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .spt-get-help p {
    width: 100%;
  }
  .spt-get-help {
    padding: 10px 25px;
    flex-direction: column;
  }

  .spt-get-help img {
    width: 80px;
    border-radius: 100%;
    margin-right: 20px;
  }
  .spt-get-help * {
    text-align: center;
  }
  .spt-section-style {
    border: solid 0px white;
    padding: 10px;
    border-radius: 10px;
  }
  .spt-section-margin-top {
    margin-top: 20px;
  }
  .spt-body-font {
    font-size: 0.9rem;
  }

  /* ------------------ */
  .phone-vanish {
    display: none !important;
  }
  .page-title {
    font-size: var(--app-mobile-header-font);
    text-align: center;
    color: var(--app-accent-3);
  }
  .page-slogan {
    font-weight: 400;
    color: var(--app-main-color);
    text-align: center;
    /* text-transform: capitalize; */
    line-height: 1.5;
    width: 85%;
    font-size: var(--app-mobile-body-font);
    font-weight: 600;
  }

  .m-for-center {
    width: 100%;
  }
  .site-logo {
    height: 70px;
    width: 70px;
    object-fit: contain;
  }
  .mob-clear-padding {
    padding: 0px !important;
  }

  .campaign-focus-image {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 6px;
    margin-top: 15px;
  }

  .roaming-container {
    margin: 20px 0px;
  }
  .roaming-header h3 {
    font-size: 1.1rem;
  }
  .roaming-text {
    font-size: var(--mob-normal-font-size);
  }
  .mob-phone-mode {
    font-size: var(--mob-normal-font-size);
    padding: 7px 15px;
  }
  .app-btn {
    border-width: 0px;
    padding: 7px 17px;
    font-size: var(--mob-normal-font-size) !important;
  }

  .g-s-container {
    padding: 50px 10px;
  }
  .one-box {
    padding: 0px 10px 10px !important;
  }
  .one-box h5 {
    font-size: 1rem;
  }
  .one-box img {
    margin-top: 12px;
    height: 60px;
    width: 60px;
  }
  .one-box-container {
    min-height: 250px;
    margin-bottom: 20px;
  }

  .one-box p,
  a,
  Button {
    /* font-size: var(--mob-normal-font-size); */
    /*font-size: 0.77rem !important;*/
  }

  .one-box-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 20px 30px 30px; */
  }
  .one-tech-wrapper {
    padding: 0px 4px !important;
    margin-top: 90px;
  }

  .testi-container {
    padding: 20px;
    height: 200px;
    margin-bottom: 15px;
  }
  .coach-main {
    margin: 0;
    justify-content: space-between;
  }

  .coach-main img {
    width: 60px;
    height: 60px;
    /* object-fit: cover; */
  }

  .coach-main h6 {
    font-size: 0.7rem;
    text-align: center;
    margin: 5px 0px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .coach-main p {
    font-size: 11px;
  }
  .coaches-description {
    width: 100%;
    font-size: var(--mob-paragraph-font-size);
    text-align: justify;
    margin-top: 10px;
  }
  .do-more-root p {
    width: 100%;
    font-size: var(--mob-normal-font-size);
    /* text-align: justify; */
  }
  .do-more-root {
    padding: 5vh 0px;
  }

  .one-tech-main img {
    height: 200px;
    width: 100%;
    object-fit: contain;
  }

  .o-t-desc {
    font-size: var(--mob-paragraph-font-size);
  }
  .one-tech-wrapper {
    padding: 0px 15px;
  }

  .section-title {
    font-size: var(--app-mobile-header-font);
  }

  .overview-item p {
    font-size: var(--mob-paragraph-font-size);
  }

  .paragraph-font {
    font-size: var(--mob-paragraph-font-size);
  }

  .our-filter-item {
    font-size: var(--mob-paragraph-font-size);
  }

  .link-text {
    font-size: var(--mob-paragraph-font-size);
  }

  .testi-form-wrapper {
    /* border: 1px dashed rgb(230, 226, 226); */
    margin-top: 40px;
    /* padding: 20px; */
  }

  .body-font {
    font-size: var(--app-mobile-body-font);
  }

  .subheader-font {
    font-size: var(--app-mobile-subheader-font);
  }

  .header-font {
    font-size: var(--app-mobile-header-font);
  }

  .small-font {
    font-size: var(--app-mobile-small-font);
  }

  .nav-top-margin {
    margin-top: 75px;
  }

  /* ---------------------------- END OF PHONE SPECIFIC STYLES ----------- */
}

@media screen and (max-width: 576px) {
  .app-nav-bar {
    .navbar-collapse.show {
      /*min-height: 650px;*/
      /* set the height to the available viewport height */
      height: 100vh;
    }
    align-items: start !important;

    a.dropdown-toggle.nav-link {
      width: 100%;
      /*text-align: center;*/

      .nav-content {
        min-width: 250px;
      }

      &::after {
        display: none !important;
      }
    }
  }
}
