:root {
  --site-logo: 100px;
}
@media only screen and (min-width: 768px) {
  .spt-img-for-contain { 
    height: 500px !important;
  }
  .partner-img {
    height: 100px;
  }
  .contact-ribbon { 
    padding: 10px 20px;
  }
  .how-it-works-item {
    flex-basis: 28%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
  .how-it-works img {
    height: 300px;
    margin: 50px 0px;
  }
  .spt-benefits-item {
    flex-basis: 48%;
    flex-direction: row;
  }
  .spt-form-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .spt-t-area p {
    width: 80%;
    text-align: center;
  }
  .spt-t-area {
    padding: 25px;
  }
  .spt-contact-form {
    width: 45%;
    min-height: 380px;
    padding-bottom: 20px;
  }
  .spt-contact-root {
    padding: 80px;
    display: flex;
    min-height: 300px;
  }

  .spt-v2-nav { 
    display: flex;
    flex-direction: row;

  }

  /* -- SPT THEME ---- */
  .spt-v2-nav img {
    margin: 0px;
    height: 170px;
    margin-left: auto;
  }
  .spt-s-img {
    border-radius: 15px;
    width: 97%;
    object-fit: cover;
    height: 340px;
  }
  .spt-section-component {
    padding: 6vh 7vw;
  }
  .spt-section-img-area {
    display: flex;
    align-items: center;
    justify-content: var(--justify-content);
  }
  .spt-hero-media-area {
    height: 100%;
    position: relative;
    background-color: var(--spt-main-color);
  }
  .spt-about-chip {
    padding: 7px 15px;
    margin: 4px;
  }

  .spt-about-chip i {
    margin-right: 8px;
    font-size: 20px;
  }

  .play-btn {
    font-size: 90px;
    margin-bottom: 10px;
  }
  .p-button {
    padding: 10px 20px;
    margin: 0px 4px;
    font-size: 14px;
  }
  .c-align-items-center {
    align-items: center;
  }
  .c-justify-content-center {
    justify-content: center;
  }
  .col-on-pc-row-on-mobile {
    display: flex;
    flex-direction: column;
  }
  .row-on-pc-col-on-mobile {
    display: flex;
    flex-direction: row;
  }
  .spt-overlay-content {
    padding: 30px;
  }
  .smart-width {
    width: var(--smart-width) !important;
  }
  .spt-get-help .btn-area {
    margin-left: auto;
    min-width: 200px;
  }
  .custom-margin {
    margin: var(--my-custom-margin);
  }
  .spt-footer h1 {
    color: white;
    font-size: 45px;
    font-weight: normal;
    width: 67%;
  }
  .spt-footer {
    background-color: var(--background);
    width: 75%;
    color: white;
    padding: 0% 7%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .spt-acc-title h4 {
    font-weight: normal;
  }
  .spt-acc-title {
    padding: 10px 20px;
  }

  .spt-btn {
    padding: 10px 25px;
    width: fit-content;
  }
  .spt-btn-outline {
    padding: 7px 25px;
  }
  .spt-get-help h5 {
    font-weight: bold;
  }
  .spt-get-help p {
    width: 70%;
  }
  .spt-get-help img {
    width: 80px;
    border-radius: 100%;
    margin-right: 20px;
  }
  .spt-get-help {
    padding: 10px 25px;
  }
  .spt-section-style {
    border-radius: 20px;
    padding: 60px;
    min-height: 400px;
  }
  .spt-section-margin-top {
    margin-top: 40px;
  }
  .spt-body-font {
    font-size: 1.2rem;
  }
  .spt-section-padding {
    padding: 0% 7%;
  }
  .spt-section h1 {
    font-weight: normal;
  }

  .spt-section .img {
    height: 81%;
  }

  .spt-hero .intro {
    padding: 7% 13%;
  }
  .spt-hero .intro h1 {
    font-size: 88px;
  }

  /* ------------------ */
  .page-title {
    text-align: center;
    //font-size: var(--app-header-font);
    color: var(--app-accent-3);
    /* text-transform: uppercase; */
  }
  .roaming-container {
    //margin: 100px 0px;
  }
  .page-slogan {
    /*font-weight: 400;*/
    color: var(--app-main-color);
    text-align: center;
    /* text-transform: capitalize; */
    line-height: 1.5;
    width: 85%;
    //font-size: var(--app-subheader-font);
    font-weight: 600;
  }

  .site-logo {
    width: var(--site-logo);
    height: var(--site-logo);
    margin-block: 10px;
    object-fit: contain;
  }

  .campaign-focus-image {
    width: 80%;
    //margin: 20px 10% 0px;
    height: 560px;
    border-radius: 10px;
    object-fit: cover;
  }
  .roaming-header h3 {
    font-size: 23px;
  }
  .roaming-text {
    font-size: 1rem;
  }

  .app-btn {
    border-width: 0px;
    padding: 9px 19px;
  }

  .g-s-container {
    padding: 50px 20px;
  }

  .one-box {
    padding: 40px 40px 10px;
  }
  .one-box img {
    height: 100px;
    width: 100px;
  }
  .one-box-container {
    min-height: 390px;
  }
  .one-box-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 30px 30px;
  }

  .testi-container {
    padding: 20px;
    height: 350px;
    margin-bottom: 15px;
  }

  .coach-main img {
    /* margin-top: 50px; */
    width: 120px;
    height: 120px;
  }

  .coach-main p {
    font-size: 15px;
  }
  .coaches-description {
    width: 90%;
    text-align: justify;
    margin-top: 20px;
  }
  .do-more-root {
    /* padding: 20vh 0px; */
  }
  .do-more-root p {
    /* width: 80%; */
  }
  .one-coach-main h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin: 10px 0px;
    font-weight: bold;
  }

  .one-tech-main img {
    height: 420px;
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
    margin: 15px;
  }

  .vendor-list-group li {
    /* vendor-list-item */
    flex-basis: calc(33.33% - 10px);
    /* margin: 5px;
    font-weight: bold;
    text-decoration: underline;
    color: black; */
  }

  .vendor-list-group {
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
  }

  .t-with-filter-top {
    /* width: 80%; */
  }

  /* .link-text {
    font-size: 1.1rem;
  } */

  .testi-form-wrapper {
    border: 1px dashed rgb(230, 226, 226);
    margin-top: 40px;
    padding: 20px;
  }

  .pc-vanish {
    display: none !important;
  }

  .section-title {
    font-size: var(--app-header-font);
  }

  .header-font {
    font-size: var(--app-header-font);
  }
  .body-font {
    font-size: var(--app-body-font) !important;
  }
  .subheader-font {
    font-size: var(--app-subheader-font);
  }
  .small-font {
    font-size: var(--app-small-font);
  }

  .b-hero-img {
    height: 68vh;
  }

  .new-one-box img {
    object-fit: cover;
    width: 100%;
    height: 202px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .new-one-box-body {
    /*padding: 20px;*/
    /*text-align: center;*/
  }

  .new-one-box-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 30px 15px;
  }
  .nav-top-margin {
    margin-top: 100px;
  }

  .one-tech-wrapper {
    margin-top: 120px;
  }

  .normal-btn {
    font-size: 16px !important;
  }

  /* --------------------------------- END OF PC SPECIFIC ---------------------- */
}

.int-panel {
  /* border: 2px solid var(--app-accent-3); */
  border: 2px solid var(--app-main-color);
  padding: 13px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
}
.int-panel i {
  /* color: var(--app-accent-3) !important;  */
  color: var(--app-main-color) !important;
}
.int-panel small {
  color: #383838 !important;
  /* color: var(--app-main-color) !important; */
}

.camp-share-btn-v2 {
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 200px;
  background: white;
  padding: 10px 20px;
  color: var(--app-main-color);
  /* left: 47vw; */
  border-radius: 55px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-weight: bold;
  font-size: var(--app-body-font);
}

.hero-belt {
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
}
.b-hero-img {
  width: 100%;
  object-fit: cover;
}

.vendor-list-group li {
  /* vendor-list-item */
  /* flex-basis: calc(33.33% - 10px); */
  margin: 5px;
  font-weight: bold;
  text-decoration: underline;
  color: black;
}

.coach-main p {
  font-weight: bold;
  /* font-size: 15px; */
  color: var(--app-main-color);
  /* text-transform: uppercase; */
}
.coach-main img {
  border-radius: 100%;
  object-fit: cover;
}

.testi-container {
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.one-box-container {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: white;
}

.one-box img {
  object-fit: contain;
}

.one-box h5 {
  color: var(--app-medium-green);
  margin-top: 10px;
}
.one-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.roaming-header {
  padding: 10px 30px;
  /* background: var(--app-kicking-yellow); */
  color: white;
  background: var(--app-main-color);
  /* border-radius: 5px; */
  //border-top-right-radius: 5px;
  //border-top-left-radius: 5px;
}

.app-btn {
  margin-left: auto;
  border-radius: 100px !important;
  background: var(--app-main-color) !important;
  border-width: 0px !important;
}

.g-s-container {
  background: var(--app-main-color);
}

.normal-btn {
  background: var(--app-main-color);
  padding: 10px;
  color: white;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
