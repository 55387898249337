.theme-color {
  color: #6e207c;
}

.custom-letter-spacing-p {
  letter-spacing: 1px;
}

.light-red-background {
  background-color: rgb(255, 157, 157);
}

.text-link {
  cursor: pointer;
  font-weight: 500;
}

.light-gray-back {
  background-color: rgb(236, 236, 236);
}

/* ........................image styles ................ */
.smallimages-container-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.small-image-container {
  width: 200px;
  height: 100px;
  position: relative;
  /*margin-bottom: 60px;*/

  img {
    height: 100px;
  }
}

.small-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.image-close-btn {
  position: absolute;
  height: 25px;
  width: 25px;
  padding: 5px;
  top: -13px;
  border-radius: 100%;
  right: -13px;
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.5),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.small-image-text {
  margin-top: -1.2rem;
  padding-top: 2rem;
}

.image-close-btn:hover {
  background-color: #ff6565;
  color: white;
}

/* ................. goals page ...................... */

.border-dashed {
  border-style: dashed;
  padding: 2rem 1rem;
  border-color: rgb(212, 212, 212);
}

.border-no-dash {
  border-style: solid;
  padding: 2rem 1rem;
  border-color: rgb(212, 212, 212);
}

/*  .................. Navigation tabs  ........................ */

.tab-active {
  color: white !important;
  background-color: #6e207c;
}

.nav-tabs {
  text-align: center;
  width: 100%;
  white-space: pre;
  margin-bottom: 0;
  border-bottom: none;
}

.nav-tabs-main:hover {
  background-color: #be63ce;
  border-color: #be63ce;
  color: white;
}

.nav-tabs-main {
  padding: 1rem;
  display: flex;
  width: 100% !important;
  border: 1px solid #6e207c;
  border-right: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
}

.nav-tabs-main:last-child {
  border-right: 1px solid #6e207c;
}

.nav-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0;
  color: #6e207c;
}

.nav-tabs-container::-webkit-scrollbar {
  display: none;
}

.nav-tabs-main::-webkit-scrollbar {
  display: none;
}

.btn-fill {
  background-color: transparent;
  color: #6e207c;
  font-weight: bold;
  padding: 1rem;
  border: solid 2px #6e207c;
  width: 100%;
}

/* .....................  sidebar  ............................ */

.sidebar-con {
  height: 100vh;
  width: 20%;
}

.sidebar-container-main {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
}

/* ................... Preview Campaigns ................ */
.gradient-bg {
  /* background-image: linear-gradient(to right, #80b1ff, #ffa2fd); */
  /*width: 100%;*/
  height: 25rem;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
		url("https://admin.risingkashmir.com/Source/News/29447324-0b29-4a35-94b8-424c1a3d6971.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.title--container {
  // background-color: #20203f;
  background-color: #3d0746;
  // background-color: white;
  margin-top: -90px;
  height: 330px;
  color: white;
  padding: 2rem;
  box-shadow: var(--elevate-float-pro);
  /*width: 95%;*/
}

.campaign-page-link {
  color: white;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: 1px;
}

.campaign-page-link:hover {
  color: pink;
}

.campaign-text {
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-btn-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.update-btn-con .active {
  color: rgb(79, 255, 91);
  text-transform: uppercase;
  text-decoration: underline;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 1.2rem;
  font-weight: bolder;
  background-color: transparent;
}

.update-btn-con .inactive {
  color: rgb(255, 88, 79);
  text-transform: uppercase;
  text-decoration: underline;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 1.2rem;
  font-weight: bolder;
  background-color: transparent;
}

.disable-btn {
  border: none;
  padding: 0.7rem 2rem;
  background-color: rgb(255, 73, 73);
  color: white;
  font-weight: bold;
}

.update-btn-con button {
  padding: 0.7rem 2rem;
  border: none;
  background-color: #f5b8ff;
}

.title-txt {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.camp-date {
  color: rgb(215, 215, 215);
}

/* .camp-img {
	width: 100%;
	height: 100%;
	top: 0;
	  background-image: url('path/to/image.jpg');

} */

.stats-cont {
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-top: 50px;
  background-color: rgb(255, 240, 255);
  padding-bottom: 2rem;
}

.d-all-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

/* .stats-cont h3 {
	background-color: rgb(255, 240, 255);
} */

.statss-con-div {
  display: flex;
  width: 100%;
  // gap: 30px;
  flex-wrap: wrap;
  justify-content: start;
  padding: 2rem 0.4rem;
  border-radius: 20px;
}

.tabs-con {
  display: flex;
  gap: 20px;
}

.bg-green {
  background-color: rgb(14, 60, 15);
}

.searchbar {
  width: 100%;
  padding: 10px 10px;
  border-radius: 7px;
  border: 1px solid gray;
  margin-bottom: 25px;
  margin-top: 15px;
  letter-spacing: 1px;

  &:hover {
    border-color: #6e207c;
  }

  &:focus {
    border-color: #6e207c;
    outline: solid rgba(110, 32, 124, 0.51);
  }
}

.search-icon {
  position: absolute;
  right: 17px;
  top: 28px;
  color: #6e207c;
}

.v-btn-h:hover {
  background-color: #3d0746;
}

.test-show-opt {
  background-color: #6e207c;
  color: white !important;
}

.test-tab-opts {
}

/* ............................ del btn  ....................... */
.delbutton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.delbutton:hover {
  //width: 100px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.delbutton:hover .svgIcon {
  //width: 30px;
  transition-duration: 0.3s;
  //transform: translateY(60%);
}

.delbutton::before {
  //position: absolute;
  //top: -30px;
  //content: "Delete";
  //color: white;
  //transition-duration: 0.3s;
  //font-size: 2px;
}

.delbutton:hover::before {
  font-size: 13px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: 0.3s;
}

.tracking-wide {
  letter-spacing: 0.05em; /* Adjust the value as needed */
}

.rotate-180 {
  transform: rotate(180deg) !important;
  display: inline-block;
  transition-duration: 300ms;
  transition-property: all;
}

.text-lg {
  font-size: large;
}

.adtxt {
  color: #6e207c;
  border: 1px solid #6e207c;
}

.modal-content {
  overflow-y: unset !important;
}

.modal-body {
  overflow-y: auto;
}
